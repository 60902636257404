@tailwind components;
@tailwind base;
@tailwind utilities;


body {
  font-family: 'Roboto', sans-serif;
}

.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

/* produt features */
.product-features {
  ul {
    li {
      display: flex;
      padding: 0.5rem 0;
      border-bottom: 1px solid #eaeaea;
      &:last-child {
        border-bottom: none;
      }  
    }
  }
}

/* GALLERY */

.image-container img {
  width: '100%';
  transition: transform 0.5s ease, filter 0.5s ease;
}

.image-container:hover img {
  filter: brightness(50%);
}

.image-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  text-align: center;
  transition: opacity 0.5s ease;
  opacity: 0;
  pointer-events: none;
}

.image-container:hover .image-title {
  opacity: 1;
}